<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<footer class="text-lg-start">
  <!-- Top Footer -->
  <div class="container px-3 py-4">
    <div *ngIf="showTopFooter" class="top-footer d-flex flex-wrap flex-column mb-3">
      <div class="my-auto">
        <img [alt]="'footer.fao-sustainable-development-goals.logo' | translate"
             [src]="getLogo()">
      </div>
      <div class="my-auto">
        <div>
          <h6 class="small font-weight-bold text-uppercase">{{ 'footer.follow-us-on' | translate }}</h6>
          <ul class="social-links list-unstyled justify-content-start mb-0">
            <li>
              <a href="https://www.facebook.com/UNFAO">
                <img [alt]="'footer.social.facebook.icon' | translate"
                     src="/assets/client/images/social/icon-facebook.svg"/>
              </a>
            </li>
            <li>
              <a href="https://www.flickr.com/photos/faonews/albums/">
                <img [alt]="'footer.social.flickr.icon' | translate"
                     src="/assets/client/images/social/icon-flickr.svg"/>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/fao/">
                <img [alt]="'footer.social.instagram.icon' | translate"
                     src="/assets/client/images/social/icon-instagram.svg"/>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/fao/">
                <img [alt]="'footer.social.linkedin.icon' | translate"
                     src="/assets/client/images/social/icon-linkedin.svg"/>
              </a>
            </li>
            <li>
              <a href="#">
                <img [alt]="'footer.social.rss.icon' | translate"
                     src="/assets/client/images/social/icon-rss.svg"/>
              </a>
            </li>
<!--            <li>
              <a href="https://www.slideshare.net/FAOoftheUN">
                <img [alt]="'footer.social.slideshare.icon' | translate"
                     src="/assets/client/images/social/icon-slideshare.svg"/>
              </a>
            </li>-->
            <li>
              <a href="https://soundcloud.com/unfao">
                <img [alt]="'footer.social.soundcloud.icon' | translate"
                     src="/assets/client/images/social/icon-soundcloud.svg"/>
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@fao">
                <img [alt]="'footer.social.tiktok.icon' | translate"
                     src="/assets/client/images/social/icon-tiktok.svg"/>
              </a>
            </li>
            <li>
              <a
                href="https://www.toutiao.com/c/user/token/MS4wLjABAAAAddfBLLYL2GZzXAetR_paFVV1-M4fdAWpUxiROxuOpl7pDGuXLCGfYg_VOogU135J/?wid=1650826195609">
                <img [alt]="'footer.social.toutiao.icon' | translate"
                     src="/assets/client/images/social/icon-toutiao.svg"/>
              </a>
            </li>
            <li>
              <a href="#">
                <img [alt]="'footer.social.wechat.icon' | translate"
                     src="/assets/client/images/social/icon-wechat.svg"/>
              </a>
            </li>
            <li>
              <a href="https://www.weibo.com/unfao">
                <img [alt]="'footer.social.weibo.icon' | translate"
                     src="/assets/client/images/social/icon-weibo.svg"/>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/FAO">
                <img [alt]="'footer.social.twitter.icon' | translate"
                     src="/assets/client/images/social/icon-twitter.svg"/>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/user/FAOoftheUN">
                <img [alt]="'footer.social.youtube.icon' | translate"
                     src="/assets/client/images/social/icon-youtube.svg"/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Top Footer -->
    <!-- Bottom Footer -->
    <div class="bottom-footer d-flex">
      <div class="content-container d-flex flex-wrap flex-column">
        <div>
          <ul class="list-unstyled small d-flex gap-1 mb-0">
            <li>
              <a href="{{ 'footer.link.fao-organizational-chart.link' | translate }}">
                {{ 'footer.link.fao-organizational-chart' | translate }}
              </a>
            </li>
            <li class="position-relative" (dsClickOutside)="showWorldWideOffices = false">
              <button type="button" (click)="showWorldWideOffices = !showWorldWideOffices">
                {{ 'footer.link.worldwide-offices' | translate }}
                <i [ngClass]="showWorldWideOffices ? 'fa-chevron-down' : 'fa-chevron-up'" class="fas"></i>
              </button>
              <ul [class]="showWorldWideOffices ? 'd-flex flex-column list-unstyled mx-2' : 'd-none'">
                <li>
                  <a href="{{ 'footer.link.worldwide-offices.africa.link' | translate }}" target="_self">
                    {{ 'footer.link.worldwide-offices.africa' | translate }}
                  </a>
                </li>
                <li>
                  <a href="{{ 'footer.link.worldwide-offices.asia-pacific.link' | translate }}" target="_self">
                    {{ 'footer.link.worldwide-offices.asia-pacific' | translate }}
                  </a>
                </li>
                <li>
                  <a href="{{ 'footer.link.worldwide-offices.europe-central-asia.link' | translate }}" target="_self">
                    {{ 'footer.link.worldwide-offices.europe-central-asia' | translate }}
                  </a>
                </li>
                <li>
                  <a href="{{ 'footer.link.worldwide-offices.latin-america-caribbean.link' | translate }}" target="_self">
                    {{ 'footer.link.worldwide-offices.latin-america-caribbean' | translate }}
                  </a>
                </li>
                <li>
                  <a href="{{ 'footer.link.worldwide-offices.near-east-and-north-africa.link' | translate }}" target="_self">
                    {{ 'footer.link.worldwide-offices.near-east-and-north-africa' | translate }}
                  </a>
                </li>
                <li>
                  <a href="{{ 'footer.link.worldwide-offices.country-offices.link' | translate }}" target="_self">
                    {{ 'footer.link.worldwide-offices.country-offices' | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <hr class="my-2">
          <ul class="list-unstyled list-with-separator small d-flex mb-0">
            <li>
              <a href="{{ 'footer.link.jobs.link' | translate }}">
                {{ 'footer.link.jobs' | translate }}
              </a>
            </li>
            <li>
              <a href="{{ 'footer.link.contact-us.link' | translate }}">
                {{ 'footer.link.contact-us' | translate }}
              </a>
            </li>
            <li>
              <a href="{{ 'footer.link.terms-and-conditions.link' | translate }}">
                {{ 'footer.link.terms-and-conditions' | translate }}
              </a>
            </li>
            <li>
              <a href="{{ 'footer.link.data-protection-and-privacy.link' | translate }}">
                {{ 'footer.link.data-protection-and-privacy' | translate }}
              </a>
            </li>
            <li>
              <a href="{{ 'footer.link.scam-alert.link' | translate }}">
                {{ 'footer.link.scam-alert' | translate }}
              </a>
            </li>
            <li>
              <a href="{{ 'footer.link.report-misconduct.link' | translate }}">
                {{ 'footer.link.report-misconduct' | translate }}
              </a>
            </li>
          </ul>
        </div>
        <div>
<!--          <h6 class="small font-weight-bold text-uppercase">{{ 'footer.download-our-app' | translate }}</h6>
          <div class="mobile-stores d-flex flex-wrap mb-3">
            <a href="https://play.google.com/store/apps/details?id=org.fao.mobile">
              <img [alt]="'footer.google-play.logo' | translate" src="/assets/client/images/google-play-logo.svg">
            </a>
            <a href="https://apps.apple.com/us/app/faonow/id877618174">
              <img [alt]="'footer.app-store.logo' | translate" src="/assets/client/images/app-store-logo.svg">
            </a>
          </div>-->
          <a class="small" href="{{ 'footer.copyright.link' | translate }}">
            {{ 'footer.copyright' | translate:{ year: dateObj | date:'y' } }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Bottom Footer -->
</footer>
