/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {Component, Inject, OnInit} from '@angular/core';
import { FooterComponent as BaseComponent } from '../../../../app/footer/footer.component';
import {LangConfig} from '../../../../config/lang-config.interface';
import {KlaroService} from '../../../../app/shared/cookies/klaro.service';
import {APP_CONFIG, AppConfig} from '../../../../config/app-config.interface';
import {LocaleService} from '../../../../app/core/locale/locale.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ds-footer',
  styleUrls: ['footer.component.scss'],
  // styleUrls: ['../../../../app/footer/footer.component.scss'],
  templateUrl: 'footer.component.html'
  // templateUrl: '../../../../app/footer/footer.component.html'
})
export class FooterComponent extends BaseComponent implements OnInit {
  showTopFooter = true;
  showWorldWideOffices = false;
  activeLanguages: string[] = [];
  currentLocale: string;


  constructor(cookies: KlaroService,
              @Inject(APP_CONFIG) protected appConfig: AppConfig,
              protected translateService: TranslateService,
              protected localeService: LocaleService) {
    super(cookies);
  }

  ngOnInit(): void {
    this.activeLanguages = this.appConfig.languages
      .filter((langConfig: LangConfig) => langConfig.active === true)
      .map((langConfig: LangConfig) => langConfig.code);
    this.currentLocale = this.localeService.getCurrentLanguageCode();
  }

  getLogo(): string {
    let language: string = this.translateService.currentLang;
    if (!this.activeLanguages.includes(language)) {
      language = 'en';
    }
    return `assets/client/images/logo/fao-logo-footer-${language}.svg`;
  }
}
