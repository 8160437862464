<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<header>
  <div class="container">
    <div class="d-flex flex-row justify-content-between">
      <a class="navbar-brand my-2 text-white" href="{{getFaoLink()}}" target="_blank">
        <img [src]="getLogo()" [attr.alt]="'menu.header.image.logo' | translate"/>
      </a>

      <nav role="navigation" [attr.aria-label]="'nav.user.description' | translate"
           class="navbar navbar-light navbar-expand-md flex-shrink-0 px-0">
        <a href="/" class="header-link mr-4">
          <span *ngIf="currentLocale == 'fr' || currentLocale == 'ru' || currentLocale == 'es'; else otherSpan"
                class="my-auto">
            {{ 'knowledge-repository' | translate }}
            <strong>{{ 'fao' | translate }}</strong>
            <span class="text-danger font-weight-bold">{{'beta' | translate}}</span>
          </span>
          <ng-template #otherSpan>
          <span class="my-auto">
            <strong>{{ 'fao' | translate }}</strong>
            {{ 'knowledge-repository' | translate }}
            <span class="text-danger font-weight-bold">{{'beta' | translate}}</span>
          </span>
          </ng-template>
        </a>
        <ds-atmire-search-navbar></ds-atmire-search-navbar>
        <ds-themed-lang-switch></ds-themed-lang-switch>
        <ds-themed-auth-nav-menu></ds-themed-auth-nav-menu>
        <ds-impersonate-navbar></ds-impersonate-navbar>
        <div class="pl-2">
          <button class="navbar-toggler" type="button" (click)="toggleNavbar()"
                  aria-controls="collapsingNav"
                  aria-expanded="false" [attr.aria-label]="'nav.toggle' | translate">
            <span class="navbar-toggler-icon fas fa-bars fa-fw" aria-hidden="true"></span>
          </button>
        </div>
      </nav>
    </div>
  </div>
</header>
