/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Inject, OnInit } from '@angular/core';
import { HeaderComponent as BaseComponent } from '../../../atmire/app/header/header.component';
import { MenuService } from '../../../../app/shared/menu/menu.service';
import { AuthService } from '../../../../app/core/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, AppConfig } from '../../../../config/app-config.interface';
import { LangConfig } from '../../../../config/lang-config.interface';
import {LocaleService} from '../../../../app/core/locale/locale.service';

/**
 * Represents the header with the logo and simple navigation
 */
@Component({
  selector: 'ds-header',
  // styleUrls: ['header.component.scss'],
  styleUrls: ['./header.component.scss', '../../../../app/header/header.component.scss'],
  templateUrl: './header.component.html',
  // templateUrl: '../../../../app/header/header.component.html',
})
export class HeaderComponent extends BaseComponent implements OnInit {

  activeLanguages: string[] = [];
  faoHome = 'https://www.fao.org/home/';
  currentLocale: string;

  constructor(
    menuService: MenuService,
    protected authService: AuthService,
    protected translateService: TranslateService,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    protected localeService: LocaleService
  ) {
    super(menuService);
  }

  ngOnInit(): void {
    this.activeLanguages = this.appConfig.languages
      .filter((langConfig: LangConfig) => langConfig.active === true)
      .map((langConfig: LangConfig) => langConfig.code);
    this.currentLocale = this.localeService.getCurrentLanguageCode();
  }

  getLogo(): string {
    let language: string = this.translateService.currentLang;
    if (!this.activeLanguages.includes(language)) {
      language = 'en';
    }
    return `assets/client/images/logo/fao-logo-${language}.svg`;
  }

  getFaoLink() {
    if (this.activeLanguages.includes(this.currentLocale)) {
      return this.faoHome + this.currentLocale;
    } else {
      return this.faoHome + 'en';
    }
  }

}
